import * as React from 'react'
import RichText from './rich-text'
import TabControl from '../tab-control'

const StrapiTabControl = ({ tabs }) => {
    tabs = tabs.map(tab => ({
        id: tab.id,
        name: tab.name,
        element: <RichText markdown={tab.content} />
    }));

    return <TabControl tabs={tabs} />
}

export default StrapiTabControl
