import * as React from 'react'
import classNames from 'classnames'

const Table = ({ header, data }) => {
    const paddingHorizontalClass = 'px-2';
    const paddingBottomClass = 'pb-6';

    return (
        <table className="w-full">
            <thead className="whitespace-nowrap text-left">
                <tr>
                    {header.map(x => (
                        <th className={classNames(paddingHorizontalClass, "pb-2", x.className)}>{x.text}</th>
                    ))}
                </tr>
            </thead>
            <tbody className={classNames(data.className)}>
                {data.rows.map((row, i) => {
                    let applyPadding = {};
                    applyPadding[paddingBottomClass] = i != data.rows.length - 1;

                    return (
                        <tr>
                            {row.map(x => (
                                <td className={classNames(x.className, paddingHorizontalClass, applyPadding)}>{x.element}</td>
                            ))}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default Table
