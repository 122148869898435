import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import RichText from '../components/generic/rich-text'
import Image from '../components/generic/image'
import ActionCard from '../components/generic/action-card'
import YouTubeVideo from '../components/generic/youtube-video'
import PriceAndFeatures from '../components/course-details/price-and-features'
import StrapiTabControl from '../components/generic/strapi-tab-control'
import UpcomingCourses from '../components/course-details/upcoming-courses'

const TrainingPage = ({ data }) => {
  data = data.allStrapiCourse.nodes[0]

  return (
    <Layout title={data.fullName}>
      <div className="flex items-center space-x-3 mb-6">
        <img className="w-16 h-16" src={data.token.localFile.publicURL} />
        <h1 className="flex flex-col justify-center">
          <h1 className="font-semibold text-xl">{data.title}</h1>
          {data.subtitle &&
            <h2 className="text-base font-normal text-muted-200">{data.subtitle}</h2>
          }
        </h1>
      </div>
      <div className="space-y-8">
        {data.page.map(component => (
          <div key={component.id}>
            {component.strapi_component === 'course-details.banner' &&
              <div className="grid grid-cols-12 space-y-8 sm:space-y-0 items-center">
                <div className="col-span-12 sm:col-span-6 lg:col-span-8">
                  <Image media={component.image.media} />
                </div>
                <div className="col-span-12 sm:col-span-6 lg:col-span-4 px-4">
                  <PriceAndFeatures data={component.priceAndFeatures} />
                </div>
              </div>
            }

            {component.strapi_component === 'generic.tab-control' &&
              <StrapiTabControl tabs={component.tabs} />
            }

            {component.strapi_component === 'generic.rich-text' &&
              <RichText headerLevelStart={2} markdown={component.content} />
            }

            {component.strapi_component === 'generic.image' &&
              <Image media={component.media} />
            }

            {component.strapi_component === 'course-details.action-card-and-you-tube-video' &&
              <div className="grid grid-cols-12 space-y-8 sm:space-y-0 items-center">
                <div className="col-span-12 sm:col-span-6 md:col-span-4 sm:pr-4">
                  <ActionCard data={component.actionCard} />
                </div>
                <div className="col-span-12 sm:col-span-6 md:col-span-8">
                  <YouTubeVideo videoId={component.youtubeVideo.videoId} />
                </div>
              </div>
            }
          </div>
        ))}

        <div>
          <h2 className="text-3xl mb-4">Upcoming courses</h2>
          <UpcomingCourses courses={[data, data, data]} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query($slug: String!, $language: String!) {
  allStrapiCourse(filter: {locale: {eq: $language}, slug: {eq: $slug}}) {
    nodes {
      id
      fullName
      title
      subtitle
      token {
        localFile {
          publicURL
        }
      }
      page {
        id
        # Component type
        strapi_component
        # Banner image component
        image {
          media {
            caption
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        # Normal image component
        media {
            caption
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
        }
        # Banner price and features component
        priceAndFeatures {
          currency
          price
          features {
            id
            content
          }
        }
        # Tab control
        tabs {
          id
          name
          content
        }
        # Action cards
        actionCard {
            id
            title
            content
            actionText
            actionSlug
        }
        # YouTube videos
        youtubeVideo {
            id
            videoId
        }
        # Rich text content
        content
      }
    }
  }
}
`

export default TrainingPage
