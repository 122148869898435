import * as React from 'react'

const TabControl = ({ tabs }) => {
    const [shownIndex, setShownIndex] = React.useState(0);

    return (
        <div className="space-y-1">
            <div className="text-sm inline-flex gap-4 flex-wrap sm:text-base justify-center">
                {tabs.map((tab, index) => (
                    <button
                        key={tab.id}
                        className={`sm:border-b-2 border-transparent sm:bg-transparent rounded sm:rounded-none p-2 sm:pb-1.5 sm:p-0 flex-grow  ${shownIndex == index ? "border-transparent sm:border-sf-orange bg-sf-orange text-white sm:text-black sm:bg-transparent" : "bg-gray-100 text-muted-200 hover:text-muted-300 hover:border-muted-100"}`}
                        onClick={() => setShownIndex(index)}
                    >
                        {tab.name}
                    </button>
                ))}
            </div>
            <div>
                {tabs[shownIndex].element}
            </div>
        </div>
    )
}

export default TabControl
