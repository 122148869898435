import * as React from 'react'
import Table from '../table'
import Token from '../token'

const UpcomingCourses = ({ courses }) => {
    const header = [
        {
            text: 'Course'
        },
        {
            text: 'Date and time'
        },
        {
            text: 'Place'
        },
        {
            className: "hidden lg:table-cell",
            text: 'Language'
        },
        {
            className: "whitespace-normal hidden lg:table-cell",
            text: 'Details'
        },
        {
            text: 'More'
        }
    ];

    const data = {
        className: "whitespace-nowrap",
        rows: [
            [
                {
                    element: (<Token course={courses[0]}></Token>)
                },
                {
                    element: (<span>Sep 30 - Oct 1 <br />9 AM - 5 PM</span>)
                },
                {
                    element: (<span>Utrecht, Netherlands</span>)
                },
                {
                    className: "hidden lg:table-cell",
                    element: (<span>Dutch</span>)
                },
                {
                    className: "whitespace-normal hidden lg:table-cell",
                    element: <span>Vestibulum sed fringilla est, a tincidunt sapien. Nunc ac vestibulum nisi, et vestibulum arcu.</span>
                },
                {
                    element: <button className="btn btn-muted-outlined">Show details</button>
                }
            ]
        ]
    };
    data.rows = [data.rows[0], data.rows[0], data.rows[0]];

    return (
        <div className="w-full">
            <div className="w-full hidden md:block">
                <Table header={header} data={data} />
            </div>
            <div className="md:hidden space-y-4">
                {courses.map(course => (
                    <div className="flex items-center cursor-pointer">
                        <div>
                            <div className="flex items-center space-x-3">
                                <div className="w-12 h-12">
                                    <img src={course.token.localFile.publicURL} />
                                </div>
                                <div className="flex-1 flex-col justify-center">
                                    <div className="mb-2">
                                        <div>{course.title}</div>
                                        {course.subtitle &&
                                            <div className="text-sm text-muted-200" style={{ lineHeight: 0.7 }}>{course.subtitle}</div>
                                        }
                                    </div>
                                    <span className="text-sm text-muted-200">Sep 30 - Oct 1 • Utrecht, Netherlands</span>
                                </div>
                            </div>
                        </div>
                        <div className="material-icons-outlined flex-grow text-center">
                            arrow_forward
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default UpcomingCourses
