import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Image = ({ media }) => {
    return (
        <GatsbyImage imgClassName="rounded" image={media.localFile.childImageSharp.gatsbyImageData} alt={media.caption} />
    )
}

export default Image
