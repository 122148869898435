import * as React from 'react'

const PriceAndFeatures = ({ data }) => {
    const currencyMap = {
        euro: '€',
        poundSterling: '£'
    };

    return (
        <div className="flex flex-col space-y-4">
            <div className="text-3xl self-center">{currencyMap[data.currency]} {data.price}</div>
            <div className="space-y-4 self-center">
                {data.features.map(feature => (
                    <div key={feature.id} className="flex items-center space-x-1">
                        <span className="material-icons text-sf-orange select-none">
                            check_box
                        </span>
                        <span>{feature.content}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PriceAndFeatures
