import * as React from 'react'

const YouTubeVideo = ({ videoId }) => {
    const ref = React.useRef<HTMLIFrameElement>();

    // iframes work in mysterious ways. We will force the video to be a certain height based
    // on the width of the component its parent. This way we'll keep the aspect ratio the same.
    React.useEffect(() => {
        // Force a 19:6 aspect ratio as that is by the most common one
        function handleResize() {
            ref.current.style.height = `${Math.round(ref.current.clientWidth * 0.5625)}px`;
        }
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    return (
        <iframe ref={ref} className="rounded"
            width="100%"
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowFullScreen />
    )
}

export default YouTubeVideo
