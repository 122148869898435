import * as React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'
import parse, { domToReact } from 'html-react-parser'
const showdown = require('showdown')

const RichText = ({ markdown, headerLevelStart }) => {
    // Configure the Markdown converted and parse the provided text
    const converter = new showdown.Converter({
        strikethrough: true,
        headerLevelStart
    });
    const html = converter.makeHtml(markdown);

    // Because we use Gatsby and support localization, we need to make use the Link component
    // instead of the normal 'a' tag. So we parse the rendered HTML as React components and replace
    // all 'a' tag elements with Link components. 
    const elements = parse(html, {
        replace: (node: any) => {
            const children = node.children ? domToReact(node.children) : [];

            // Tailwind Typography does not support h4 and h6 headings
            if (node.name === 'h5' || node.name === 'h6') {
                return (<h4>{children}</h4>)
            }

            if (node.name === 'a') {
                const href = node.attribs.href;

                // Check if the URL leads to an external site
                const urlRegex = /^https?:\/\//gm;
                return urlRegex.test(href)
                    ? (<a className="link" href={href} target="_blank">{children}</a>)
                    : (<Link className="link" to={href}>{children}</Link>);
            }
        }
    });

    // Finally we can render all of the elements
    return (
        <div className="prose max-w-full">
            {elements}
        </div>
    )
}

RichText.defaultProps = {
    headerLevelStart: 1
};

export default RichText
