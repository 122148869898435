import * as React from 'react'
import { Link } from "gatsby-plugin-react-i18next"
import RichText from "./rich-text"

const ActionCard = ({ data }) => {
    return (
        <div className="space-y-4 md:text-center">
            <h2>{data.title}</h2>
            <RichText markdown={data.content} />
            <Link className="btn btn-large btn-sf-orange w-full block" to={data.actionSlug}>{data.actionText}</Link>
        </div>
    )
}

export default ActionCard
